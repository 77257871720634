import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/localization.png';
import video1 from 'video/updates/localization-feature-news.webm';
import styled from 'styled-components';
import VideoModal from 'components/ui/video-modal';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
  }
  h3 {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const VideoContainer = styled.div`
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
`;

const Content = () => {
  return (
    <Container>
      <p>
        Now, in addition to your user’s country, you will find out which city it is from. You can
        also filter recordings from a specific city.
      </p>

      <p className="mt-5 mb-5">
        <VideoContainer>
          <VideoModal className="video-shadow" src={{ webm: video1 }} />
        </VideoContainer>
      </p>

      <p className="mt-3">
        We hope you like it! Other new improvements are coming soon. If you think something needs
        improvement, or do you have an idea for functionality? Write to us:{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'More accurate location of the user',
  url: '/blog/more-accurate-location-of-the-user',
  description: 'Find out exactly where your users are from',
  author,
  img,
  date: '2019-08-16',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    group={frontmatter.group}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    descriptionSocial={frontmatter.descriptionSocial || frontmatter.description}
  />
);
